import {bymoTemplateMetadataFactoryCreate} from "../../../doppe-sdk";


export namespace BymoTemplatePagePreview {

    export interface TemplateProps {
        showSocialIconsSkeleton: boolean;
        showProfileTitleSkeleton: boolean;
        showProfileBioSkeleton: boolean;
        mobileContentInlinePaddingCss: string;
    }
}

export const BymoTemplatePagePreviewMetadata = bymoTemplateMetadataFactoryCreate<BymoTemplatePagePreview.TemplateProps>()({
    initialValues: {
        showSocialIconsSkeleton: true,
        showProfileTitleSkeleton: true,
        showProfileBioSkeleton: true,
        mobileContentInlinePaddingCss: '18px'
    },
    features: [
        'hasActions', 'hasActionButton'
    ]
})